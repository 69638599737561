import "./App.scss" //updated
import { ShowPosts } from "./showPosts"


function App() {
  return (
    <div className="app-contents">
      <ShowPosts />
    </div>
  );
}

export default App;
