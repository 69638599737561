import { useState, useEffect } from "react";
import axios from "axios";

const API_URL = "https://social-app.alie.workers.dev"

const Post = ({ data }) => {
    const { title, username, content } = data;
    return (
        <li>
            <div className="title-description">
                <h3>{title}</h3>
                <h4>{username}</h4>
                <p>{content}</p>
            </div>
        </li>
    )
}


export const ShowPosts = () => {
    const [post, setPost] = useState([]);

    // get posts here
    useEffect(() => {
        axios
            .get(API_URL + "/posts")
            .then((res) => {
                console.log(res.data);
                setPost(res.data);
            }).catch((err) => {
                console.log(err);
            });

    }, []);

    return (
        <section className="container">
            <section className="contents">
                <h1>Social App</h1>
                <ul className="list-container">
                    {post.map((data) => (
                        <Post data={data} />
                    ))}
                </ul>
            </section>
        </section>
    )
}